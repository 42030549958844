<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div
      class="
        dark-light-icon
        bookmark-wrapper
        align-items-center
        flex-grow-1
        d-none d-lg-flex
      "
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>
    <div class="auth-inner py-2">
      <!-- EmailConfirmation -->
      <b-card class="mb-0">
        <b-link :to="{ name: 'login' }" class="brand-logo">
          <h2 class="brand-text text-primary ml-1">FiqOn</h2>
          <b-img
            fluid
            class="fiqon-logo"
            :class="logoUrl ? '' : 'light-logo'"
            :src="logoImg"
            alt="Email Confirm"
          />
        </b-link>

        <b-card-title class="mb-1"> Verify your Email 🔒 </b-card-title>
        <b-card-text class="mb-2">
          We sent a confirmation code to your email.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="codeValidation">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- Confirmation Code -->
            <b-form-group
              label="Confirmation Code"
              label-for="email-confirmation-code"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirmation Code"
                rules="required"
              >
                <b-form-input
                  id="email-confirmation-code"
                  v-model="userConfirmationCode"
                  :state="errors.length > 0 ? false : null"
                  name="email-confirmation-code"
                  placeholder="- - - - - - "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" block type="submit">
              Validate Email
            </b-button>
            <b-button
              variant="secondary"
              block
              id="resend-email"
              @click="sendEmailConfirmation()"
              :disabled="timerEnabled"
            >
              Resend Confirmation
              {{ timerEnabled ? `${$t("common.terms.in")} ${timerCount} s` : "" }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link @click="backToLogin">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Email Confirmation-->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BImg,
} from "bootstrap-vue";
import { required } from "@validations";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import store from "@/store/index";
import Animation from "/src/layouts/components/Animation.vue";
import { makeToast } from "@/layouts/components/Popups";
import custom from "@/custom";

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BImg,
    DarkToggler,
    Animation,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userConfirmationCode: "",
      // validation
      required,
      logoImg: require("@/assets/images/logo/logo.svg"),
      timerEnabled: false,
      timerCount: 30,
    };
  },
  computed: {
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return false;
      }
      return true;
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
          if(this.timerCount == 0) {
            this.timerEnabled = false;
          }
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
            if(this.timerCount == 0) {
              this.timerEnabled = false;
            }
          }, 1000);
        }
      },
    },
  },
  methods: {
    sendEmailConfirmation() {
      this.$store
        .dispatch("sendEmailConfirmation")
        .then((response) => {
          this.timerEnabled = true;
          makeToast(custom.infoMessages.verify_email);
        })
        .catch((error) => {
          makeToast(custom.infoMessages[error.code]);
        });
    },
    validationForm() {
      this.$refs.codeValidation.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("emailConfirmation", this.userConfirmationCode)
            .then((response) => {
              if (response) {
                this.$router.push({ name: "home" }).then(() => {
                  makeToast(custom.successMessages.email_verified);
                });
              } else {
                throw response;
              }
            })
            .catch((error) => {
              makeToast({
                title: this.$t("login.toast.verify_email.error.title"),
                text: this.$t("login.toast.verify_email.error.message"),
                variant: "danger",
                icon: "XIcon",
              });
            });
        }
      });
    },
    // async preventButtonSpam(){
    //   console.log('OK')
    //   setTimeout(async () => await this.toogleButton(true), 3000)
    //   console.log('BOA DEMAIS')
    // // },
    // toogleButton(state){
    //   return this.disabledTemp = state
    // },
    backToLogin() {
      localStorage.removeItem("sessionKey")
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.fiqon-logo-welcome {
  height: 17px;
}
.brand-name {
  color: $primary;
}
</style>